import React from "react"
import Layout from "../../components/modules/Layout"

import FadeInBottomEffect from "../../components/effects/FadeInBottomEffect"
import BlockWrapper from "../../components/technical/BlockWrapper"
import FadeInLeftEffect from "../../components/effects/FadeInLeftEffect"
import FadeInRightEffect from "../../components/effects/FadeInRightEffect"
import FadeInCharsEffect from "../../components/effects/FadeInCharsEffect"

const AnimationsPage = () => {
  return (
    <Layout>
      <BlockWrapper block={{}} className="w-full h-screen py-32 bg-gray-300">
        <h1 className="text-center headline headline-h1 text-primary">
          Bitte herunter scrollen um Effekte zu sehen.
        </h1>
      </BlockWrapper>

      <BlockWrapper block={{}} className="w-full py-32 mb-64 bg-gray-100">
        <FadeInCharsEffect tagName="div" className="mb-24 text-4xl">
          FadeIn-Effekt für die einzelnen Buchstaben eines Textes
        </FadeInCharsEffect>
        <div className="flex flex-row -mx-grid">
          <div className="w-1/3 px-grid">
            <FadeInLeftEffect tagName="div" className="w-full">
              <div className="w-full p-4 text-2xl text-center text-white aspect-square bg-primary">
                FadeIn von Links
              </div>
            </FadeInLeftEffect>
          </div>
          <div className="w-1/3 px-grid">
            <FadeInBottomEffect tagName="div" className="w-full">
              <div className="w-full p-4 text-2xl text-center text-white aspect-square bg-primary">
                FadeIn von Unten
              </div>
            </FadeInBottomEffect>
          </div>
          <div className="w-1/3 px-grid">
            <FadeInRightEffect tagName="div" className="w-full">
              <div className="w-full p-4 text-2xl text-center text-white aspect-square bg-primary">
                FadeIn von Rechts
              </div>
            </FadeInRightEffect>
          </div>
        </div>
      </BlockWrapper>
    </Layout>
  )
}

export default AnimationsPage
