import React, { useEffect, useRef } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const FadeInLeftEffect = ({
  children,
  tagName = "div",
  className,
  distance = "-100",
  easing = "power1.out",
  scrubbing = 2,
  opacity = "0",
  ...props
}) => {
  const TagName = tagName
  const refFadeInLeftEffect = useRef(null)

  useEffect(() => {
    gsap.from(refFadeInLeftEffect.current, {
      autoAlpha: opacity,
      x: distance,
      ease: easing,
      scrollTrigger: {
        scrub: scrubbing,
        trigger: refFadeInLeftEffect.current,
        toggleActions: "play none none none",
        start: "start 100%",
        end: "bottom 90%",
      },
    })
  }, [])

  return (
    <TagName ref={refFadeInLeftEffect} className={className} {...props}>
      {children}
    </TagName>
  )
}

export default FadeInLeftEffect
